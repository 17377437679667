import {Menu, MenuProps} from "antd";
import React from "react";
import {AuditOutlined, ContactsOutlined, DotChartOutlined, IdcardOutlined, TeamOutlined, FileImageOutlined, CalculatorOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {MenuInfo} from "rc-menu/lib/interface"
import {getRoutePath} from "../utils/main";

const items: MenuProps['items'] = [
    {
        key: "document-processing",
        icon: React.createElement(DotChartOutlined),
        label: "Document Processing"
    },
    {
        key: "id-processing",
        icon: React.createElement(IdcardOutlined),
        label: "Egyptian ID Processing"
    },
    {
        key: "face-identity-verification",
        icon: React.createElement(TeamOutlined),
        label: "Face Identity Verification"
    },
    {
        key: "document-verification",
        icon: React.createElement(ContactsOutlined),
        label: "Egyptian ID Verification"
    },
    {
        key: "liveness-verification",
        icon: React.createElement(AuditOutlined),
        label: "Liveness Verification"
    },
    {
        key: "mrz-processing",
        icon: React.createElement(FileImageOutlined),
        label: "MRZ Processing"
    },
    {
        key: "age-gender-processing",
        icon: React.createElement(CalculatorOutlined),
        label: "Age/Gender Estimation"
    },
]
const AppMenu = ({active}: {active?: string}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const path = getRoutePath(location, params);
    const actualActive = active || path;
    const navigateTo = async (info: MenuInfo) => {
        return navigate("/"+info.key)
    }
    return (
        <Menu theme="dark" mode="inline" selectedKeys={[actualActive?.replace(new RegExp(`^/+`), '') || '']} items={items} selectable={false} onClick={navigateTo}/>
    )
}
export default AppMenu;
