import {Progress, Button} from "antd";

const ProgressBar = ({value, onCancel}) => {
    return (
        <div className="absolute top-0 left-0 bottom-0 z-40 flex justify-center items-center flex-wrap h-full w-full bg-white opacity-90 flex-col gap-4">
            <div className="w-1/2 self-center block relative items-stretch">
                <Progress progress={value} />
            </div>
            <div className="text-blue-900 font-black w-full text-center opacity-100">Uploading, Please wait....</div>
            <Button onClick={onCancel} color="failure" size="sm">Cancel</Button>
        </div>
    )
}

export default ProgressBar;
