import React, {useContext, useState} from 'react';
import {Button, Divider, Drawer, Input, Layout, theme} from 'antd';
import {Link} from 'react-router-dom';
import logo from "../static/logo.png";
import verticalLogo from "../static/logo-vertical.png";
import AppMenu from "./menu";
import bg from '../static/bg.svg'
import {LockOutlined} from '@ant-design/icons';
import {AppStore} from "../stores/app-store";
import {useAuth} from "../stores/auth-store";

const { Header, Content, Footer, Sider } = Layout;

const Master = ({children, title=""}: {children: any, title: string}) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {userDetails} = useContext(AppStore);
    const {apiToken, setApiToken} = userDetails;
    const [newToken, setNewValue] = useState(apiToken);
    const {logout} = useAuth();

    return (
        <Layout className="relative" style={{backgroundRepeat: 'no-repeat', background: colorBgContainer, backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Sider
                width={250}
                breakpoint="sm"
                style={{
                    overflow: 'auto',
                    minHeight: "100vh",
                }}
                onCollapse={(collapsed)=>{setIsCollapsed(collapsed)}}
            >
                <Link to={`/`}>
                    <img src={isCollapsed ? verticalLogo : logo} className={isCollapsed ? "logo-sm" : "logo"} alt="logo"/>
                </Link>
                <Divider />
                <AppMenu />
            </Sider>
            <Layout className="bg-transparent">
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <div className="flex items-center flex-row h-full">
                        <p className="font-bolder lg:font-black my-0 ml-5 text-xl lg:text-2xl">{title}</p>
                        <p className="ml-auto my-0 mr-5 font-bold text-xl"><LockOutlined className="cursor-pointer" onClick={logout}/></p>
                    </div>
                </Header>

                <Content className="relative">
                    <div className="absolute h-full w-full backdrop-blur backdrop-brightness-75 backdrop-opacity-60 bg-gray-200/70" />
                    <div className="p-5 relative h-full">
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}><>Idefy ©{new Date().getFullYear()}</></Footer>
            </Layout>
            <Drawer
                title="Authorization"
                placement="bottom"
                closable={false}
                onClose={setDrawerOpen.bind(this, false)}
                open={drawerOpen}
                key="bottom"
            >
                <Input type="password" addonBefore="Bearer " value={newToken} onChange={(event)=>setNewValue(event.target.value)}/>
                <div className="flex justify-center py-2">
                    <Button type="primary" className="px-10" onClick={setApiToken.bind(this, newToken)}>Save</Button>
                </div>
            </Drawer>
        </Layout>
    );
};

export default Master;
