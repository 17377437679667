import {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Image, Row, Select, Table} from "antd";
import Base from "../../layouts/base";
import {prettify} from "../../utils/main";
import ImageUpload from "../../components/ui/image-upload";
import {SizeType} from "antd/es/config-provider/SizeContext";

const columns = [
    {
        title: 'Field',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
];
const pagination = {
    defaultPageSize: 50
};
const AgeGender = ({onSubmit}: any) => {
    const [result, setResult] = useState<readonly object[]>([])
    const [error, setError] = useState<{ description_en: string }|null>(null);
    const [image, setImage] = useState("");

    const setAPIResult = (responseValue: object) => {
        const newData: object[]= [];
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            for (const key in result) {
                if (result[key] != ""){
                    newData.push({
                        key: key,
                        name: prettify(key),
                        value: result[key]
                    })
                }
            }
            setResult(newData);
            setError(null);
        }
        else{
            setResult(newData);
            setError(result);
        }
    }

    const submitHandler = async () => {
        await onSubmit("/api/proxy/age_gender", "POST", {
            "image": image.split(',')[1],
        }, true, {}, {}, setAPIResult);
    }
    return (
        <Row className="text-left bg-transparent text-black" align="middle">
            <Col xs={24}>
                <div className="w-full xl:w-1/2 mx-auto">
                    <ImageUpload onChange={setImage} label="Image" image={image}/>
                </div>
            </Col>
            <Col xs={24}>
                <div className="w-1/2 mx-auto mt-5 text-center">
                    <Button type="primary" size={'large' as SizeType} className="!px-10" onClick={submitHandler}>
                        Submit
                    </Button>
                </div>
            </Col>
            <Divider />
            <div className="w-full">
                {result.length > 0 && (
                    <>
                        <Table dataSource={result} columns={columns} pagination={pagination}/>
                    </>
                )}
                {error && (
                    <Alert
                        className="text-left"
                        message="Error"
                        description={error.description_en}
                        type="error"
                        showIcon
                    />
                )
                }
            </div>
        </Row>)
}
export default Base(AgeGender, "Age/Gender Estimation");
