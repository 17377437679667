import React, { useState, useRef } from "react";
import AuthMaster from "../../layouts/auth-master";
import {Form, Input, Button, notification, message} from 'antd';
import { UserOutlined, LockOutlined, MessageOutlined } from '@ant-design/icons';
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../stores/auth-store";

interface ResetPasswordFormValues {
    email: string;
    password: string;
    code: string;
}

const PasswordResetPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<ResetPasswordFormValues>({
        email: "",
        code: "",
        password: "",
    });
    const formRef = useRef<any>();
    const {reset, resetRequest} = useAuth();
    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await reset(formValues.email, formValues.code, formValues.password);
            navigate("/login");
        } catch (error) {
            console.error(error);
            // Handle error
        }
        setIsLoading(false)
    };
    const passwordResetRequest = async () => {
        if (!formValues.email){
            message.error("Please enter an email");
        }
        else {
            setIsLoading(true)
            await resetRequest(formValues.email);
        }
        setIsLoading(false)
    }
    return (
        <AuthMaster title="Reset Password">
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Email" disabled={isLoading}
                           onChange={(event) => {setFormValues(prev => {return {...prev, email: event.target.value}})}}/>
                </Form.Item>
                <Form.Item
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
                >
                    <div className="flex gap-2">
                    <Input prefix={<MessageOutlined />} placeholder="Code" disabled={isLoading}
                           onChange={(event) => {setFormValues(prev => {return {...prev, code: event.target.value}})}}/>
                        <Button type="primary" disabled={isLoading} onClick={passwordResetRequest}>
                            Get Code
                        </Button>
                    </div>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password prefix={<LockOutlined />} placeholder="New Password" disabled={isLoading}
                                    onChange={(event) => {setFormValues(prev => {return {...prev, password: event.target.value}})}}/>
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    rules={[
                        { required: true, message: "Please confirm your password!" },
                        {
                            validator: (_, value) =>{
                                return value && value !== formValues.password
                                    ? Promise.reject(new Error("Passwords do not match!"))
                                    : Promise.resolve()},
                        },
                    ]}
                >
                    <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" disabled={isLoading}/>
                </Form.Item>
                <Form.Item>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Reset
                        </Button>
                    </div>
                </Form.Item>

            </Form>
            <p>
                <Link to="/login" className="text-white">Login Instead</Link>
            </p>
        </AuthMaster>
    );
};
export default PasswordResetPage;
