import Base from "../../layouts/base";
import {useState} from "react";
import {Alert, Button, Col, Divider, Result, Row} from "antd";
import ImageUpload from "../../components/ui/image-upload";
import {SizeType} from "antd/es/config-provider/SizeContext";

const DocumentVerification = ({onSubmit}: any) => {
    const [result, setResult] = useState<any|null>(null)
    const [error, setError] = useState<{ description_en: string }|null>(null)
    const [firstImage, setFirstImage] = useState("");
    const [secondImage, setBackImage] = useState("");

    const setAPIResult = (responseValue: object) => {
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            setResult(result);
            setError(null);
        }
        else{
            setResult(null);
            setError(result);
        }
    }
    const submitHandler = async () => {
        await onSubmit("/api/proxy/document_verification", "POST", {
            "first_image": firstImage.split(',')[1],
            "second_image": secondImage.split(',')[1],
        }, true, {}, {}, setAPIResult);
    }
    return (
        <>
            <Row gutter={10}>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setFirstImage} label="Front ENID while Eagle Watermark is Obvious" image={firstImage}/>
                </Col>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setBackImage} label="Front ENID while Eagle Watermark is Hidden" image={secondImage}/>
                </Col>
            </Row>
            <div className="mt-10 flex justify-center items-center">
                <Button type="primary" size={'large' as SizeType} onClick={submitHandler}>
                    Submit
                </Button>
            </div>
            <div className="flex justify-end items-center">
                    For ID Verification of other countries: ,
                    <a target="_blank" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" href="https://idefy.ai/contact/" >Contact us</a>
            </div>
            <Divider />
            {result && (
                <Result
                    status={result.is_verified ? "success": "error"}
                    title={result.is_verified ?"Reference and Target images match" : "Reference and Target images don't match"}
                    subTitle={result.Message}
                />
            )}
            {error && (
                <Alert
                    className="text-left"
                    message="Error"
                    description={error.description_en}
                    type="error"
                    showIcon
                />
            )
            }
        </>
    )
}
export default Base(DocumentVerification, "Egyptian ID Verification");
