import {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Image, Row, Select, Table} from "antd";
import Base from "../../layouts/base";
import {prettify} from "../../utils/main";
import ImageUpload from "../../components/ui/image-upload";
import {SizeType} from "antd/es/config-provider/SizeContext";

const columns = [
    {
        title: 'Field',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
];
const pagination = {
    defaultPageSize: 50
};
const DocumentProcessing = ({onSubmit}: any) => {
    const [result, setResult] = useState<readonly object[]>([])
    const [error, setError] = useState<{ description_en: string }|null>(null);
    const [availableDocuments, setAvailableDocuments] = useState<any>({});
    const [availableCountries, setAvailableCountries] = useState<{value: any, label: string}[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<any|null>(null);
    const [selectedCountry, setSelectedCountry] = useState<any|null>(null);
    const [image, setImage] = useState("");
    const [images, setImages] = useState<any>({})
    const [imageFields, setImageFields] = useState(['cropped_image' ]);


    const setAPIResult = (responseValue: object) => {
        const newData: object[]= [];
        setImageFields(['cropped_image' ]);
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            for (const key in result) {
                if (imageFields.includes(key)) {
                    setImages((prev: any) => {const ad: any={}; ad[key] = result[key];return {...prev, ...ad}})
                }
                else if(key.toLowerCase().includes("image")){
                    setImageFields(prevState => [...prevState, key]);
                    setImages((prev: any) => {const ad: any={}; ad[key] = result[key];return {...prev, ...ad}})
                }
                else {
                    newData.push({
                        key: key,
                        name: prettify(key),
                        value: result[key]
                    })
                }
            }
            setResult(newData);
            setError(null);
        }
        else{
            setResult(newData);
            setError(result);
        }
    }

    const submitHandler = async () => {
        await onSubmit("/api/proxy/document_processing", "POST", {
            "image": image.split(',')[1],
            "return_cropped_images": true,
            "document_type": `${selectedCountry}_${selectedDocument}`
        }, true, {}, {}, setAPIResult);
    }
    useEffect(() => {
        const loadSupportedDocuments = async () => {
            await onSubmit("/api/proxy/document_processing/get_supported_documents", "GET", {
            }, true, {}, {}, (response: any) => {
                if (response.status === 200){
                    let documents: any = {};
                    for (const document of response.result.supported_documents){
                        const country = document.split("_")[0];
                        if (!country){
                            continue
                        }
                        documents[country] = documents[country] ? documents[country] : [];
                        const document_type = document.split("_")[1];
                        if (!document_type){
                            continue;
                        }
                        documents[country].push({value: document_type, label: prettify(document_type)});
                    }
                    setAvailableDocuments(documents);
                    setAvailableCountries(Object.keys(documents).map(
                        (item: string) => {return {
                            value: item, label: prettify(item)}
                        }
                    ))
                }
            });
        };
        loadSupportedDocuments()
    }, [])
    return (
        <Row className="text-left bg-transparent text-black" align="middle">
            <Col xs={24}>
                <div className="w-full xl:w-1/2 mx-auto">
                    <ImageUpload onChange={setImage} label="Image" image={image}/>
                </div>
            </Col>
            <Col xs={24}>
                <div className="w-full xl:w-1/2 mx-auto mt-5">
                    <p className="font-bold">Select Document Country: </p>
                    <Select
                        placeholder="Select a document country"
                        className="w-full pl-5"
                        value={selectedCountry}
                        onChange={(value: any)=>{setSelectedCountry(value)}}
                        options={availableCountries}
                    />
                </div>
            </Col>
            <Col xs={24}>
                <div className="w-full xl:w-1/2 mx-auto mt-5">
                    <p className="font-bold">Select Document Type: </p>
                    <Select
                        placeholder="Select a document type"
                        className="w-full pl-5"
                        value={selectedDocument}
                        onChange={(value: any)=>{setSelectedDocument(value)}}
                        options={selectedCountry?(availableDocuments[selectedCountry]):([])}
                    />
                </div>
            </Col>
            <Col xs={24}>
                <div className="w-1/2 mx-auto mt-5 text-center">
                    <Button type="primary" size={'large' as SizeType} className="!px-10" onClick={submitHandler}>
                        Submit
                    </Button>
                </div>
            </Col>
            <Divider />
            <div className="w-full">
                {result.length > 0 && (
                    <>
                        <Row align="middle" gutter={10} justify="center">
                            {imageFields.map(image_name => (
                                <Col xs={24} sm={12} md={6} lg={3}>
                                    <Image
                                        style={{maxHeight: '200px'}}
                                        src={"data:image/png;base64,"+images[image_name]}
                                    />
                                    <p className="capitalize">{prettify(image_name)}</p>
                                </Col>
                            ))}
                        </Row>
                        <Table dataSource={result} columns={columns} pagination={pagination}/>
                    </>
                )}
                {error && (
                    <Alert
                        className="text-left"
                        message="Error"
                        description={error.description_en}
                        type="error"
                        showIcon
                    />
                )
                }
            </div>
        </Row>)
}
export default Base(DocumentProcessing, "Document Processing");
