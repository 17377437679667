import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Index from './pages/landing';
import reportWebVitals from './reportWebVitals';

import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter as Router,
    Routes,
    Route,
    Link, useLocation, Navigate
} from "react-router-dom";
import Master from "./layouts/master";
import IDProcessingPage from "./pages/id-processing";
import FaceIdentityVerification from "./pages/face-identity-verification";
import DocumentVerification from "./pages/document-verification";
import LivenessVerification from "./pages/liveness-verification";
import DocumentProcessing from "./pages/document-processing";
import AppStoreProvider from "./stores/app-store";
import MRZProcessing from "./pages/mrz-processing";
import RegistrationPage from "./pages/auth/register";
import {AuthProvider, useAuth} from "./stores/auth-store";
import LoginForm from "./pages/auth/login";
import {RequireAuth, RequireGuest} from "./utils/auth";
import PasswordResetPage from "./pages/auth/password-reset";
import AgeGender from "./pages/age-gender";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Master />,
//     children: [
//       {
//         path: "",
//         element: <Index />,
//         loader: async ({ params }) => {console.log(params);return {title: "Home"}},
//       },
//       {
//         loader: async ({ params }) => {return {title: "Egyptian ID Processing"}},
//         path: "id-processing",
//         element: <IDProcessingPage />,
//       },
//       {
//         loader: async ({ params }) => {return {title: "Face Identity Verification"}},
//         path: "face-identity-verification",
//         element: <FaceIdentityVerification />,
//       },
//       {
//         loader: async ({ params }) => {return {title: "Egyptian ID Verification"}},
//         path: "document-verification",
//         element: <DocumentVerification />,
//       },
//       {
//         loader: async ({ params }) => {return {title: "Liveness Verification"}},
//         path: "liveness-verification",
//         element: <LivenessVerification />,
//       },
//       {
//         loader: async ({ params }) => {return {title: "Document Processing"}},
//         path: "document-processing",
//         element: <DocumentProcessing />,
//       },
//       {
//         loader: async ({ params }) => {return {title: "MRZ Processing"}},
//         path: "mrz-processing",
//         element: <MRZProcessing />,
//       },
//
//     ]
//   },
// ]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AppStoreProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="" element={<RequireAuth><Index /></RequireAuth>}/>
                        <Route path="/register" element={<RequireGuest><RegistrationPage /></RequireGuest>} />
                        <Route path="/login" element={<RequireGuest><LoginForm /></RequireGuest>} />
                        <Route path="/reset-password" element={<RequireGuest><PasswordResetPage /></RequireGuest>} />
                        <Route path="/id-processing" element={<RequireAuth><IDProcessingPage /></RequireAuth>} />
                        <Route path="/face-identity-verification" element={<RequireAuth><FaceIdentityVerification /></RequireAuth>}/>
                        <Route path="/document-verification" element={<RequireAuth><DocumentVerification /></RequireAuth>}/>
                        <Route path="/liveness-verification" element={<RequireAuth><LivenessVerification /></RequireAuth>}/>
                        <Route path="/document-processing" element={<RequireAuth><DocumentProcessing /></RequireAuth>}/>
                        <Route path="/mrz-processing" element={<RequireAuth><MRZProcessing /></RequireAuth>}/>
                        <Route path="/age-gender-processing" element={<RequireAuth><AgeGender /></RequireAuth>}/>
                    </Routes>
                </Router>
            </AuthProvider>
        </AppStoreProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
