import {useEffect, useState} from "react";
import {Button, Progress} from "antd";

const Waiting = ({onCancel}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const dummyUpdate = () => {
            if (value < 80) {
                setTimeout(() => {
                    setValue(value+1);
                }, 500);
            }
        }
        dummyUpdate();
    }, [value])
    return (
        <div className="absolute top-0 left-0 bottom-0 z-40 flex justify-center items-center flex-wrap h-full w-full bg-white opacity-90 flex-col gap-4">
            <div className="w-1/2 self-center block relative items-stretch">
                <Progress percent={value} />
            </div>
            <div className="text-blue-900 font-black w-full text-center opacity-100">Processing, Please wait....</div>
            {onCancel && <Button onClick={onCancel} type="default" size="sm" danger>Cancel</Button> }
        </div>
    )
}

export default Waiting;
