import {useRef} from "react";
import axios from "axios";
import {useContext} from "react";
import {AppStore} from "../../stores/app-store";
import {useAuth} from "../../stores/auth-store";

const useApi = (onProgress=(value)=>{}, onProcessStart, onResult) => {
    const controller = useRef();
    const {userDetails} = useContext(AppStore);
    const {token} = useAuth();

    const sendRequest = async (url, method, data, isJson, extraConfig, extraResultPayload, onResultCallback=onResult) => {
        const progressHandler = (progressEvent) => {
            const val = Math.floor(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            onProgress(val);
            if (val === 100) {
                onProgress(null);
                onProcessStart(true);
            }
        }
        let jsonHeaders = {'Accept': 'application/json',
            'Content-Type': 'application/json', "Authorization": "Bearer "+token}
        if (isJson){
            if (extraConfig?.headers){
                extraConfig.headers = {
                    ...extraConfig.headers,
                    ...jsonHeaders
                }
            }
            else {
                extraConfig.headers = jsonHeaders;
            }
        }
        if(!onResultCallback){
            onResultCallback=onResult;
        }
        let responseValue;
        try {
            if (method === "GET") {
                onProcessStart(true);
            }
            controller.current = new AbortController()
            const response = await axios.request({
                url: `${process.env.REACT_APP_BASE_BATH || ""}${url}`,
                method,
                data,
                signal: controller.current.signal,
                onUploadProgress: progressHandler,
                ...extraConfig
            });
            responseValue = {
                "status": response.status,
                "result": response.data,
                ...extraResultPayload
            }
        } catch (e) {
            if (e.name === "CanceledError") {
                return;
            }
            if (e?.response?.data?.error !== undefined) {
                responseValue = {
                    "status": e.response.status,
                    "result": e.response.data,
                    ...extraResultPayload
                }
            } else {
                responseValue = {
                    "status": 500,
                    "result": {
                        "error": "Couldn't connect to the service.",
                        "description": "Couldn't connect to the service now please try again later.",
                        "description_en": "Couldn't connect to the service now, please try again later.",
                        "description_ar": "لم نستطع الإتصال بمزود الخدمة حاليا من فضلك حاول لاحقا",
                        ...(e?.response?.data || {}),
                    },
                    ...extraResultPayload
                }
            }
        }
        await onResultCallback(responseValue);
    }
    const abortRequest = () => {
        controller.current.abort()
    }
    return [sendRequest, abortRequest]
}

export default useApi;
