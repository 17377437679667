import React, {Dispatch, SetStateAction} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import {getBase64} from "../../utils/main";
import {RcFile} from "antd/lib/upload";

const { Dragger } = Upload;

const ImageUpload = ({onChange, label, image} : {onChange: Dispatch<SetStateAction<string>>, label: string, image: string}) => {
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        customRequest: () => {},
        beforeUpload: async (file) => {
            onChange(await getBase64(file as RcFile))
            return false;
        },
        onDrop: async (e) => {
            onChange(await getBase64(e.dataTransfer.files[0] as RcFile));
        },
        onRemove: () => {
            onChange("")
        }
    };
    return <Dragger {...props}>
        <p className="ant-upload-drag-icon">
            {image ? <img alt="User selected" src={image} style={{maxHeight: '200px'}}/> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">{label}</p>
        <p className="ant-upload-hint">
            Click or drag file to this area to upload
        </p>
    </Dragger>
}
export default ImageUpload;
