import {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Image, Row, Select, Table} from "antd";
import Base from "../../layouts/base";
import {prettify} from "../../utils/main";
import ImageUpload from "../../components/ui/image-upload";
import {SizeType} from "antd/es/config-provider/SizeContext";

const columns = [
    {
        title: 'Field',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
];
const pagination = {
    defaultPageSize: 50
};
const MRZProcessing = ({onSubmit}: any) => {
    const [result, setResult] = useState<readonly object[]>([])
    const [error, setError] = useState<{ description_en: string }|null>(null);
    const [image, setImage] = useState("");
    const [images, setImages] = useState<any>({})
    const [imageFields, setImageFields] = useState<string[]>([]);

    const setAPIResult = (responseValue: object) => {
        const newData: object[]= [];
        setImageFields([]);
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            for (const key in result) {
                if (imageFields.includes(key)) {
                    setImages((prev: any) => {const ad: any={}; ad[key] = result[key];return {...prev, ...ad}})
                }
                else if(key.startsWith('line')){
                    setImageFields(prevState => [...prevState, key]);
                    setImages((prev: any) => {const ad: any={}; ad[key] = result[key];return {...prev, ...ad}})
                }
                else if (result[key] != ""){
                    newData.push({
                        key: key,
                        name: prettify(key),
                        value: result[key]
                    })
                }
            }
            setResult(newData);
            setError(null);
        }
        else{
            setResult(newData);
            setError(result);
        }
    }

    const submitHandler = async () => {
        await onSubmit("/api/proxy/mrz_processing", "POST", {
            "image": image.split(',')[1],
            "return_cropped_images": true,
            "enable_corners_detection": true,
        }, true, {}, {}, setAPIResult);
    }
    return (
        <Row className="text-left bg-transparent text-black" align="middle">
            <Col xs={24}>
                <div className="w-full xl:w-1/2 mx-auto">
                    <ImageUpload onChange={setImage} label="Image" image={image}/>
                </div>
            </Col>
            <Col xs={24}>
                <div className="w-1/2 mx-auto mt-5 text-center">
                    <Button type="primary" size={'large' as SizeType} className="!px-10" onClick={submitHandler}>
                        Submit
                    </Button>
                </div>
            </Col>
            <Divider />
            <div className="w-full">
                {result.length > 0 && (
                    <>
                        <Row align="middle" gutter={10} justify="center">
                            {imageFields.map(image_name => (
                                <>
                                    {
                                        images[image_name] &&
                                        <Col xs={24} sm={12} md={6} lg={3}>
                                            <Image
                                                style={{maxHeight: '200px'}}
                                                src={"data:image/png;base64," + images[image_name]}
                                            />
                                            <p className="capitalize">{prettify(image_name)}</p>
                                        </Col>
                                    }
                                </>
                            ))}
                        </Row>
                        <Table dataSource={result} columns={columns} pagination={pagination}/>
                    </>
                )}
                {error && (
                    <Alert
                        className="text-left"
                        message="Error"
                        description={error.description_en}
                        type="error"
                        showIcon
                    />
                )
                }
            </div>
        </Row>)
}
export default Base(MRZProcessing, "MRZ Processing");
