import ProgressBar from "../components/ui/progress";
import Waiting from "../components/ui/waiting";
import {useState} from "react";
import useApi from "../components/hooks/useApi";
import Master from "./master";

const Base = (WrappedPage: any, title: string="") => {
    const WrappedComponent = ({wrappedName, onResult=(val: any)=>{}, ...rest}: any) => {
        const [isProcessing, setIsProcessing] = useState(false);
        const [uploadProgress, setUploadProgress] = useState(null);

        const setAPIResult = (responseValue: any) => {
                setIsProcessing(false);
                onResult(responseValue)
        }

        const [sendRequest, cancelRequest] = useApi(setUploadProgress, setIsProcessing, setAPIResult);
        const formSubmitHandler = async (url: string | null = null, method: string, formData: any, isJson=false, rest = {}, extraPayload={}, resultCallback: (()=>void) | null=null) => {
            url = url || wrappedName;
            const originalUrl = url;
            url = url === wrappedName ? `/api/proxy/${url}` : url;
            if(resultCallback) {
                const oldResultCallback = resultCallback;
                resultCallback = (...data: []) => {setIsProcessing(false);oldResultCallback(...data);}
            }
            await sendRequest(url, method, formData, isJson, rest, {"url": originalUrl, ...extraPayload}, resultCallback);
        }

        const cancelRequestHandler = () => {
            cancelRequest();
            setIsProcessing(false);
        }
        return (
            <Master title={title}>
                {uploadProgress && <ProgressBar value={uploadProgress} onCancel={cancelRequestHandler}/>}
                {isProcessing && <Waiting onCancel={cancelRequestHandler}/>}
                <WrappedPage onSubmit={formSubmitHandler} onResult={setAPIResult} {...rest}/>
            </Master>
        )
    }
    return WrappedComponent;
}
export default Base;
