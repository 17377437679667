import {createContext, useState} from "react";

export const AppStore = createContext({
    userDetails: {apiToken: "", setApiToken: (newToken: string)=>{}}
});

const AppStoreProvider = ({children}: {children: any}) => {
    const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') || "BAD TOKEN");
    const apiTokenChangeHandler = (newToken: string) => {
        setApiToken(newToken);
        localStorage.setItem('apiToken', newToken);
    }
    const userDetails = {
        apiToken,
        setApiToken: apiTokenChangeHandler
    }

    return (<AppStore.Provider value={{userDetails}}>
        {children}
    </AppStore.Provider>)
}

export default AppStoreProvider;
