import {createContext, useContext, useEffect, useState} from "react";
import { message } from "antd";
import axios from "axios";
import {fullUrl} from "../utils/main";

interface User {
    email: string;
    password: string;
}

interface AuthContextType {
    token: string;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    isLoading: boolean;
    signup: (email: string, password: string) => Promise<void>;
    resetRequest: (email: string) => Promise<void>;
    reset: (email: string, reset_password_code: string, password: string) => Promise<void>;
}

const initialAuthContext: AuthContextType = {
    token: "",
    login: async (email: string, password: string) => {},
    logout: () => {},
    isLoading: false,
    signup: async (email: string, password: string) => {},
    resetRequest: async (email: string) => {},
    reset: async (email: string, reset_password_code: string, password: string) => {}
};

export const AuthContext = createContext<AuthContextType>(initialAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: {children: any}) => {
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const login = async (email: string, password: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(fullUrl("/api/proxy/login"), { email, password });
            localStorage.setItem("token", response.data.api_key);
            setToken(response.data.api_key)
            message.success("Logged in successfully");
        } catch (error: any) {
            let messages = "";
            for (const field in error.response?.data){
                messages += `${field}: ${error.response?.data[field]}`
            }
            message.error(messages || "Error logging in");
        }
        setIsLoading(false);
    };
    const resetRequest = async (email: string,) => {
        setIsLoading(true);
        try {
            const response = await axios.post(fullUrl("/api/proxy/reset_password_request"), { email});
            message.success("Code sent successfully");
        } catch (error: any) {
            let messages = "";
            for (const field in error.response?.data){
                messages += `${field}: ${error.response?.data[field]}`
            }
            message.error(messages || "Error sending code");
        }
        setIsLoading(false);
    }
    const reset = async (email: string, reset_password_code: string, password: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(fullUrl("/api/proxy/reset_password"), { email, reset_password_code, password });
            // localStorage.setItem("token", response.data.api_key);
            // setToken(response.data.api_key)
            message.success("Password reset successfully");
        } catch (error: any) {
            let messages = "";
            for (const field in error.response?.data){
                messages += `${field}: ${error.response?.data[field]}\n`
            }
            message.error(messages || "Error resetting password");
            throw error;
        }
        setIsLoading(false);
    };

    const logout = () => {
        localStorage.removeItem("token");
        setToken("");
        message.success("Logged out successfully");
    };
    const signup = async (email: string, password: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(fullUrl("/api/proxy/register"), { email, password });
            localStorage.setItem("token", response.data.api_key);
            setToken(response.data.api_key)
            message.success("Logged in successfully");
        } catch (error: any) {
            let messages = "";
            for (const field in error.response?.data){
                messages += `${field}: ${error.response?.data[field]}\n`
            }
            message.error(messages || "Error signing up");
        }
        setIsLoading(false);
    };
    useEffect(() => {
        setToken(localStorage.getItem("token") || "")
    }, [])
    return (
        <AuthContext.Provider value={{ token, login, logout, isLoading, signup, resetRequest, reset }}>
            {children}
        </AuthContext.Provider>
    );
};
