import {Layout, theme} from "antd";
import React from "react";
import bg from "../static/bg.svg";
import verticalLogo from "../static/logo-vertical.png";
import logo from "../static/logo.png";

const AuthMaster = ({children, title}: {children: any, title: string}) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout className="relative" style={{backgroundRepeat: 'no-repeat', background: colorBgContainer, backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="min-h-screen flex flex-col justify-center pt-6 pb-12 sm:px-6 lg:px-8 backdrop-blur backdrop-brightness-75 backdrop-opacity-60 bg-gray-200/70">

                <div className=" sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="py-8 px-4 shadow sm:rounded-lg sm:px-10 bg-gray-900 bg-opacity-70">
                        <div className="flex justify-center">
                            <img src={logo} className="logo" alt="logo"/>
                        </div>
                        <h2 className="text-center text-3xl font-extrabold text-white">
                            {title}
                        </h2>
                        {children}
                    </div>
                </div>
            </div>
        </Layout>
    )

}
export default AuthMaster;
