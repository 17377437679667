import React, { useState, useRef } from "react";
import AuthMaster from "../../layouts/auth-master";
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import {useAuth} from "../../stores/auth-store";

interface LoginFormValues {
    email: string;
    password: string;
}

const LoginForm = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<LoginFormValues>({
        email: "",
        password: "",
    });
    const formRef = useRef<any>();
    const {login} = useAuth();
    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await login(formValues.email, formValues.password);
        } catch (error) {
            console.error(error);
            // Handle error
        }
        setIsLoading(false)
    };

    return (
        <AuthMaster title="Login">
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Email" disabled={isLoading}
                           onChange={(event) => {setFormValues(prev => {return {password: prev.password, email: event.target.value}})}}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password prefix={<LockOutlined />} placeholder="Password" disabled={isLoading}
                                    onChange={(event) => {setFormValues(prev => {return {email: prev.email, password: event.target.value}})}}/>
                </Form.Item>
                <Form.Item>
                    <div className="flex justify-between">
                    <Link to="/reset-password" className="text-white mr-auto">Forgot Password ?</Link>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Log in
                    </Button>
                    </div>
                </Form.Item>

            </Form>
            <p>
                Don't have an account? <Link to="/register" className="text-white">Register</Link>
            </p>
        </AuthMaster>
    );
};
export default LoginForm;
