import {useState} from "react";
import {Alert, Button, Col, Divider, Image, Result, Row, Select, Switch} from "antd";
import ImageUpload from "../../components/ui/image-upload";
import {SizeType} from "antd/es/config-provider/SizeContext";
import Base from "../../layouts/base";
import {prettify} from "../../utils/main";

const imageFields: string[] = ['reference_face', 'target_face'];


const FaceIdentityVerification = ({onSubmit}: any) => {
    const [result, setResult] = useState<any|null>(null)
    const [error, setError] = useState<{ description_en: string }|null>(null)
    const [referenceImage, setReferenceImage] = useState("");
    const [targetImage, setTargetImage] = useState("");
    const [idVerification, setIdVerification] = useState(false);
    const [verifyMode, setVerifyMode] = useState('balanced');

    const setAPIResult = (responseValue: object) => {
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            setResult(result);
            setError(null);
        }
        else{
            setResult(result);
            setError(result);
        }
    }
    const submitHandler = async () => {
        await onSubmit("/api/proxy/face_identity_verification", "POST", {
            "return_cropped": true,
            "reference_image": referenceImage.split(',')[1],
            "target_image": targetImage.split(',')[1],
            "id_face_verification": idVerification,
            "verify_mode": verifyMode
        }, true, {}, {}, setAPIResult);
    }
    return (
        <>
            <Row gutter={10}>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setReferenceImage} label="Reference Image" image={referenceImage}/>
                </Col>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setTargetImage} label="Target Image" image={targetImage}/>
                </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
                <Col xs={24} md={12} className="text-left mb-5">
                    <label className="mr-2">Id Verification Mode: </label>
                    <Switch checked={idVerification} onChange={(checked)=>{setIdVerification(checked)}} checkedChildren="ID Verification Enabled" unCheckedChildren="ID Verification Disabled" />
                </Col>
                <Col xs={24} md={12} className="text-left">
                    <label className="mr-2">Verification Mode: </label>
                    <Select
                        defaultValue="balanced"
                        style={{ width: 120 }}
                        onChange={(value: string)=>{setVerifyMode(value)}}
                        options={[
                            { value: 'balanced', label: 'Balanced' },
                            { value: 'aggressive', label: 'Aggressive' },
                            { value: 'very_aggressive', label: 'Very Aggressive' },
                        ]}
                    />
                </Col>
            </Row>

            <div className="mt-10 flex justify-center items-center">
                <Button type="primary" size={'large' as SizeType} onClick={submitHandler}>
                    Submit
                </Button>
            </div>
            <Divider />
            {(result && !error) && (
                <>
                    <Row align="middle" gutter={10} justify="center">
                        {imageFields.map(image_name => (
                            <Col xs={24} sm={12} className="text-center">
                                <Image
                                    style={{maxHeight: '200px'}}
                                    src={"data:image/png;base64,"+result[image_name]}
                                />
                                <p className="capitalize">{prettify(image_name)}</p>
                            </Col>
                        ))}
                    </Row>
                    <Result
                    status={result.is_similar ? "success": "error"}
                    title={result.is_similar ?"Reference and Target images match" : "Reference and Target images don't match"}
                    subTitle={"We are " + result.confidence + "% confident"}
                />
                    </>
            )}
            {error && (
                <Alert
                    className="text-left"
                    message="Error"
                    description={error.description_en}
                    type="error"
                    showIcon
                />
            )
            }
        </>
    )
}
export default Base(FaceIdentityVerification, "Face Identity Verification");
