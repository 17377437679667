import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import {useAuth} from "../../stores/auth-store";
import AuthMaster from "../../layouts/auth-master";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

const RegistrationPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");

    const { signup } = useAuth();

    const onFinish = async (values: any) => {
        try {
            setError("");
            setLoading(true);
            await signup(values.email, values.password);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <AuthMaster title="Register an account">

        {error && <p>{error}</p>}
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: "Please input your email!" },
                                { type: "email", message: "Please enter a valid email!" },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="Email" disabled={loading}/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: "Please input your password!" },
                                { min: 6, message: "Password must be at least 6 characters." },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" onChange={(event) => {setPassword(event.target.value)}} disabled={loading}/>
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            rules={[
                                { required: true, message: "Please confirm your password!" },
                                {
                                    validator: (_, value) =>{
                                        return value && value !== password
                                            ? Promise.reject(new Error("Passwords do not match!"))
                                            : Promise.resolve()},
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" disabled={loading}/>
                        </Form.Item>


                        <Form.Item className="text-right">
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                    <p>
                        Already have an account? <Link to="/login" className="text-white">Log In</Link>
                    </p>
        </AuthMaster>
    );
};

export default RegistrationPage;
