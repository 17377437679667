import React, {useState} from 'react';
import {Alert, Button, Col, Divider, InputNumber, message, Result, Row, Select, Timeline, Upload} from 'antd';
import {VideoCameraOutlined} from '@ant-design/icons';
import Base from "../../layouts/base";
import {getBase64} from "../../utils/main";
import ReactPlayer from "react-player";
import { Typography } from 'antd';
import {SizeType} from "antd/es/config-provider/SizeContext";
import MobileStoreButton from "../../components/ui/MobileStoreButton";
const { Title } = Typography;

const LivenessVerification = ({onSubmit}: any) => {
    const [result, setResult] = useState<any|null>(null)
    const [error, setError] = useState<{ description_en: string }|null>(null)
    const [file, setFile] = useState<string | null>(null);
    const [actions, setActions] = useState<{type: string, start: number, end: number}[]>([]);

    const setAPIResult = (responseValue: object) => {
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            setResult(result);
            setError(null);
        }
        else{
            setResult(null);
            setError(result);
        }
    }
    const handleUpload = async () => {
        await onSubmit("/api/proxy/liveness_verification", "POST", {
            "video": file?.split(',')[1],
            "actions": actions.map(item => item.type)
        }, true, {}, {}, setAPIResult);
        message.success('Upload successful!');
    };
    const renderVideoPlayer = () => {
        if (file) {
            return (
                <ReactPlayer
                    url={file}
                    controls={true}
                    width="100%"
                    height="auto"
                />
            );
        }
        return null;
    };
    const AddAction = () => {
        const [currentAction, setCurrentAction] = useState<string>("Center");
        const [currentStart, setCurrentStart] = useState<number|undefined|null>();
        const [currentEnd, setCurrentEnd] = useState<number|undefined|null>();
        return (
            <div className="flex items-center gap-1 flex-wrap">
                <Select
                    defaultValue="Center"
                    onChange={(value: string)=>{setCurrentAction(value)}}
                    options={[
                        {
                            "value": "Center",
                            "label": "Center"
                        },
                        {
                            "value": "Right",
                            "label": "Right"
                        },
                        {
                            "value": "Left",
                            "label": "Left"
                        },
                        {
                            "value": "Up",
                            "label": "Up"
                        },
                        {
                            "value": "Down",
                            "label": "Down"
                        },
                        {
                            "value": "Smiling",
                            "label": "Smiling"
                        },
                        {
                            "value": "Blinking",
                            "label": "Blinking"
                        }
                    ]}
                />
                <InputNumber min={0} onChange={(value)=>setCurrentStart(value)} placeholder="Start"/>
                <InputNumber min={0} onChange={(value)=>setCurrentEnd(value)} placeholder="End"/>
                <Button type="default" size={'middle' as SizeType} onClick={()=>{
                    setActions(prev => {
                        const items = [...prev, {
                            type: currentAction as string,
                            start: currentStart as number,
                            end: currentEnd as number

                        }];
                        items.sort((a, b) => {return a.start-b.start})
                        return items
                    })
                }}>
                    Add
                </Button>
            </div>
        )
    }
    return (
        <div className="w-full text-center"><Row gutter={16}>
            <Col xs={24}>
                <Upload
                    customRequest={() => {}}
                    accept="video/*"
                    multiple={false}
                    beforeUpload={async (newFile) => {
                        const data = await getBase64(newFile);
                        setFile(data);
                        // if (videoRef.current){videoRef.current.src=data}
                        return false;
                    }}
                    maxCount={1}
                    onRemove={() => {setFile(null)}}
                >
                    <Button icon={<VideoCameraOutlined />}>Select Video</Button>
                </Upload>
            </Col>
            <Col xs={12} offset={6}>
                <div className="mt-10">
                    {renderVideoPlayer()}
                </div>
            </Col>
            <Col xs={24}>
                <div className="flex flex-col items-center mt-10">
                    <Title level={4} className="text-left mb-2">Actions</Title>
                    <Timeline className="text-left"
                              pending={<AddAction />}
                              items={actions.map(item => {return {"children": `${item.type} (${item.start} => ${item.end})`}})}
                    />
                </div>
            </Col>
            <Col xs={24}>
                <Button type="primary" onClick={handleUpload} disabled={!file}>
                    Submit
                </Button>
            </Col>
        </Row>
            <Divider />
            {result && (
                <Result
                    status={result.result.is_live ? "success": "error"}
                    title={result.result.is_live ?"Liveness is verified" : "Liveness is not verified"}
                    subTitle={ Object.entries(result.result.actions_percentage)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(", ")}
                />
            )}
            {error && (
                <Alert
                    className="text-left"
                    message="Error"
                    description={error.description_en}
                    type="error"
                    showIcon
                />
            )
            }
            <div className="mb-3">
                <p className="text-xl font-extrabold">To test it in live action please visit:</p>
                <div className="flex justify-center items-center">
                    <MobileStoreButton
                        store="ios"
                        url="https://apps.apple.com/sa/app/idefy/id1617726714"
                        linkProps={{ title: 'Idefy on iOS store' }}
                    />
                    <MobileStoreButton
                        store="android"
                        url="https://play.google.com/store/apps/details?id=com.rdi.idefy.idefy&hl=en&gl=US&pli=1"
                        linkProps={{ title: 'Idefy on google play store' }}
                    />

                </div>
            </div>
        </div>
    );
}
export default Base(LivenessVerification, "Liveness Verification");
