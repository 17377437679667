import {Alert, Button, Col, Divider, Row, Switch, Table} from "antd";
import ImageUpload from "../../components/ui/image-upload";
import {useState} from "react";
import type {SizeType} from 'antd/es/config-provider/SizeContext';
import {prettify} from "../../utils/main";
import Base from "../../layouts/base";
import { Image } from 'antd';

const imageFields = ['front_cropped_image', 'back_cropped_image', 'barcode', 'birth_date_image',  'personal_image', 'back-eagle', 'back-watermark', ];
const columns = [
    {
        title: 'Field',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
];
const pagination = {
    defaultPageSize: 50
};
const IDProcessingPage = ({onSubmit}: any) => {
    const [result, setResult] = useState<readonly object[]>([])
    const [images, setImages] = useState<any>({})
    const [isVerified, setIsVerified] = useState<boolean>(true)
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const [error, setError] = useState<{ description_en: string }|null>(null)
    const [frontImage, setFrontImage] = useState("");
    const [backImage, setBackImage] = useState("");
    const [useEnglishNumbers, setUseEnglishNumbers] = useState(true);

    const setAPIResult = (responseValue: object) => {
        const newData: object[]= [];
        // @ts-ignore
        const {result, status} = responseValue;
        if (status === 200) {
            for (const key in result) {
                if (imageFields.includes(key)) {
                    setImages((prev: any) => {const ad: any={}; ad[key] = result[key];return {...prev, ...ad}})
                }
                else if (key === 'is_verified'){
                    setIsVerified(result[key]);
                }
                else if (key === 'is_expired'){
                    setIsExpired(result[key]);
                }
                else {
                    newData.push({
                        key: key,
                        name: prettify(key),
                        value: result[key]
                    })
                }
            }
            setResult(newData);
            setError(null);
        }
        else{
            setResult(newData);
            setError(result);
        }
    }
    const submitHandler = async () => {
        await onSubmit("/api/proxy/id_processing", "POST", {
            "front_image": frontImage.split(',')[1],
            "back_image": backImage.split(',')[1],
            "english_numbers": useEnglishNumbers
        }, true, {}, {}, setAPIResult);
    }
    return (
        <>
            <Row gutter={10}>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setFrontImage} label="Front Image" image={frontImage}/>
                </Col>
                <Col xs={24} md={12}>
                    <ImageUpload onChange={setBackImage} label="Back Image" image={backImage}/>
                </Col>
            </Row>
            <div className="mt-10 flex justify-end items-center">
                <Switch defaultChecked onChange={(checked)=>{setUseEnglishNumbers(checked)}} checkedChildren="Use English Numbers" unCheckedChildren="Use Arabic Numbers" />
            </div>
            <div className="mt-10 flex justify-center items-center">
                <Button type="primary" size={'large' as SizeType} onClick={submitHandler}>
                    Submit
                </Button>
            </div>
            <Divider />
            {result.length > 0 && (
                <>
                    <Row align="middle" gutter={10} justify="center">
                        {imageFields.map(image_name => (
                            <Col xs={24} sm={12} md={6} lg={3}>
                            <Image
                                style={{maxHeight: '200px'}}
                                src={"data:image/png;base64,"+images[image_name]}
                            />
                                <p className="capitalize">{prettify(image_name)}</p>
                            </Col>
                        ))}
                    </Row>
                <Row gutter={5}>
                    <Col xs={24} sm={12}>
                    {isVerified ? (<Alert
                        className="text-left"
                        message="Attention"
                        description="The front and back id images belong to the same person"
                        type="success"
                        showIcon />
                    ) : (<Alert
                        className="text-left"
                        type="error"
                        message="Attention"
                        description="These front and back id images doesn't belong to the same person"
                        showIcon />
                    )}
                    </Col>
                    <Col xs={24} sm={12}>
                        {isExpired ? (<Alert
                            className="text-left"
                            type="error"
                            message="Attention"
                            description="This id card is expired"
                            showIcon />
                    ) : (<Alert
                        className="text-left"
                        message="Attention"
                        description="This id card is valid"
                        type="success"
                        showIcon />
                    )}
                    </Col>
                </Row>
                    <Divider />
                <Table dataSource={result} columns={columns} pagination={pagination}/>
                    </>
                )}
            {error && (
                <Alert
                    className="text-left"
                    message="Error"
                    description={error.description_en}
                    type="error"
                    showIcon
                />
                )
            }
        </>
    )
}
export default Base(IDProcessingPage, "Egyptian ID Processing");
