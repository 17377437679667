import {RcFile} from "antd/lib/upload";
import type { Location, Params } from 'react-router-dom';

export const prettify = (str: string) => {
    return str.replace(/-/g, " ").replace(/_/g, " ");
}
export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
export const getRoutePath = (location: Location, params: Params): string => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};
export const fullUrl = (url: string) => {
    return `${process.env.REACT_APP_BASE_BATH || ""}${url}`;
}
