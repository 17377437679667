import React from 'react';
import {Avatar, Card, Col, Row} from "antd";
import { ArrowRightOutlined} from '@ant-design/icons';
import id_processing from '../../static/id-processing.svg'
import face_identity_verification from '../../static/face-identity-verification.svg';
import document_verification from '../../static/document-verification.svg';
import liveness_verification from '../../static/liveness-verification.svg';
import document_processing from '../../static/id-processing.svg';

import {Link} from "react-router-dom";
import Master from "../../layouts/master";
const { Meta } = Card;

const ItemCard = ({icon, link, title, description}: {icon: any, link: string, title: string, description: string}) => {
    return (
        <Card
            className="lg:w-full mb-5 bg-gray-100"
            actions={[
                <Link to={link}><ArrowRightOutlined key="setting" /></Link>,
            ]}
        >
            <Meta
                className="text-left"
                avatar={<Avatar src={icon} size={80} shape="square"/>}
                title={title}
                description={<p className="truncate">{description}</p>}
            />
        </Card>
    )
}
function Index() {
    return (
        <Master title="Home">
            <Row align="middle" justify='start' className="p-5" gutter={{md: 20, lg: 10}}>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={document_processing} link="/document-processing" title="Document Processing" description="Perform a deep analysis of various document types."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={id_processing} link="/id-processing" title="Egyptian ID Processing" description="Perform a deep analysis of your customer’s ID."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={face_identity_verification} link="/face-identity-verification" title="Face Identity Verification" description="Intelligent biometric verification scanning in seconds."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={document_verification} link="/document-verification" title="Egyptian ID Verification" description="High-precision AI that issues alerts in case of suspicious patterns."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={liveness_verification} link="/liveness-verification" title="Liveness Verification" description="Dynamic and instant ID pre-check for maximum security."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={id_processing} link="/mrz-processing" title="MRZ Processing" description="MRZ Passport Reader."/>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                    <ItemCard icon={liveness_verification} link="/age-gender-processing" title="Age/Gender Estimation" description="Estimate the age and gender of a face in an image."/>
                </Col>

            </Row>
        </Master>
    );
}

export default Index;
